<section id="landingpage">
  <div style="animation-delay: 0.5s;" class="fadeup">
    <h4 style="margin: 20px;">Hi, my name is</h4>
  </div>
  <div style="animation-delay: 1s;" class="fadeup">
    <h1 class="heading">Robert Webb.</h1>
  </div>

  <div style="animation-delay: 1.5s;" class="fadeup">
    <h1 class="heading" style="opacity: 0.5;">I Develop things for the Web.</h1>
  </div>

  <div style="animation-delay: 2s;" class="fadeup">
    <p>I am a Web Developer, specializing in developing Web and Business applications. Currently, I am focused on
      delivering applications for users at <a href="https://www.berryworld.com/en-gb"
      target="_blank" rel="noreferrer">Berryworld</a></p>
  </div>
</section>
