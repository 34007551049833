<section id="introduction">
  <div data-aos="fade-up">
    <div class="card mb-3">
      <div class="box">
        <div class="img">
          <img src="assets\imgs\me.jpg" />
        </div>
        <h2>Robert Webb<br /><span>Web Developer</span></h2>
        <p class="card-text">
          Web Developer. Using Angular, Typescript, WebAPI 3.0 & C# for the past
          2 years with Azure DevOps to deliver business applications for
          consumers.
        </p>
        <span>
          <ul>
            <li>
              <a href="https://github.com/Robw94" target="_blank"
                ><i class="fa fa-github" aria-hidden="true"></i
              ></a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/robert-webb-4a306093/"
                target="_blank"
                ><i class="fa fa-linkedin" aria-hidden="true"></i
              ></a>
            </li>
          </ul>
        </span>
      </div>
    </div>
  </div>

</section>
