<app-splash-screen [duration]="3" [animationDuration]="1" (splashScreenShowing)="splashScreenShowing = $event">
</app-splash-screen>
<div id="wrapper" class="wrapper" *ngIf="!splashScreenShowing">

  <header>
    <nav #navbar class="navbar navbar-expand-lg navbar-dark justify-content-center align-items-start">
      <button class="navbar-toggler mr-auto" type="button" data-toggle="collapse" data-target="#navbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <!-- <span class="navbar-brand position-absolute mx-0 me">Rob Webb, Web Developer</span> -->
      <div class="navbar-collapse collapse" id="navbar">
        <ul id="nav" class="navbar-nav">
          <li class="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <a class="nav-link js-scroll-trigger nav-text" href="#introduction"><span>About</span></a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <a class="nav-link js-scroll-trigger" href="#workhistory"><span>Work History</span></a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <a class="nav-link js-scroll-trigger nav-text" href="#portfolio"><span>Projects</span></a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target="#navbarResponsive">
            <a class="nav-link js-scroll-trigger nav-text" href="#contactme"><span>Contact'</span></a>
          </li>
        </ul>
      </div>
      <button class="slide"
        onclick="location.href='https://drive.google.com/file/d/1KvQeHdLepGVXRdExhiLOZIjcZq3_ZfHT/view?usp=sharing'">&nbsp;
      </button>

    </nav>

  </header>


  <div id="application" class="application">

    <div class="application-body">
      <main>
        <app-landing-page></app-landing-page>
        <app-about></app-about>
    
        <app-work></app-work>
        <app-portfolio></app-portfolio>
        <!-- <app-contact-me></app-contact-me> -->
      </main>

    </div>
    <!-- 
    <div class="application-left">
      <ul class="social" style="list-style-type: none;">
        <li> <a href="https://github.com/Robw94" target="_blank"><i class="fa fa-github fa-3x"
              aria-hidden="true"></i></a>
        <li>
          <a href="https://www.linkedin.com/in/robert-webb-4a306093/" target="_blank"><i class="fa fa-linkedin fa-3x"
              aria-hidden="true"></i></a>
        </li>
      </ul>
    </div> -->

    <!-- <div class="application-right">
      <ul class="social" style="list-style-type: none;">
        <li> <a href="https://github.com/Robw94" target="_blank"><i class="fa fa-github fa-3x"
              aria-hidden="true"></i></a>
        <li>
          <a href="https://www.linkedin.com/in/robert-webb-4a306093/" target="_blank"><i class="fa fa-linkedin fa-3x"
              aria-hidden="true"></i></a>
        </li>
      </ul>
    </div> -->

  </div>
  <footer></footer>
</div>
