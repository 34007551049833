<section id="tools">
  <div class="container-fluid">
    <div class="pt-2 pb-2 tools-bar">
      <h1 class="d-flex justify-content-center">Tools</h1>
    </div>
    <div class="row tools-container mt-5">
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\angular.svg"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\html.png"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\css.png"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\typescript.svg"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\sql.png"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\snowflake.png"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\csharp.png"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\swagger.png"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\npm.png"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\github_alt.png"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\vscode.png"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\vs.png"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
      <div class="col-6 col-md-3 tools-card-item">
        <img
          data-aos="zoom-in-up"
          data-aos-anchor-placement="center-bottom"
          src="assets\imgs\unity.png"
          alt="..."
          class="img-fluid tools-img"
        />
      </div>
    </div>
  </div>

</section>
