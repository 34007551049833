<section data-aos="fade-up" data-aos-anchor-placement="top-bottom" id="portfolio">
  <div class="about-container">
    <h1 class="portfolio-heading">About</h1>

    <div class="inner-content">
      <div class="about">
        <p>Hello! My name is Robert, I enjoy developing websites. I became interested in Web Development shortly after
          I landed my first role as a Junior Developer at Datalytyx. Although the work was primarily ETL/Data Engineer
          related, I was able to do a few proof of concepts utilizing Angular & Azure DevOps which I enjoyed a lot more.
        </p>

        <p>Moving forward to today, I am a Web Developer delivering accessible, robust and user friendly
          Web Applications for BerryWorld which are used globally throughout the business.</p>

          <!-- <ul>
            <li>Angular</li>
            <li>TypeScript</li>
            <li>HTML & Css</li>
            <li>C# & WebAPI3.0 </li>
            <li> SQL </li>

            <li>ETL </li>
          </ul>  -->
      </div>
      <div class="skills">
        <img src="assets\imgs\me.jpg" />
        <ul id="socials">
          <li>
            <a href="https://github.com/Robw94" target="_blank"
              ><i class="fa fa-github" aria-hidden="true"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/robert-webb-4a306093/"
              target="_blank"
              ><i class="fa fa-linkedin" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
        <!-- <p>Here are a few of the technologies I am familiar with and what I have been working on recently</p>
        <ul>
          <li>Angular</li>
          <li>TypeScript</li>
          <li>HTML & Css</li>
          <li>C# & WebAPI3.0 </li>
        </ul> -->
      </div>

    </div>

  </div>
</section>
