<section id="contactme">
  <div data-aos="fade-up" data-aos-anchor-placement="center-bottom">
    <h1 class="portfolio-heading">Contact Methods</h1>
    <div class="container">
      <a>
        <div type="button" class="row contact-item">
          <div class="col-12 select">
            <span class="fa fa-envelope-square fa-7x icon-size"></span>Robert.P.Webb94@gmail.com
          </div>
        </div>
      </a>
      <a href="https://github.com/Robw94" target="_blank">
        <div type="button" class="row contact-item pointer">
          <div class="col-12 select">
            <span class="fa fa-github fa-7x icon-size"></span> Robw94
          </div>
        </div>
      </a>
      <a href="https://www.linkedin.com/in/robert-webb-4a306093/" target="_blank">
        <div type="button" class="row contact-item pointer">
          <div class="col-12 select">
            <span class="fa fa-linkedin fa-7x icon-size"></span>
            <span> Robert Webb</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</section>
