<div class="container mb-2 mt-2">
  <div style="padding: 0%">
    <img style="width: 480px; height: 270px" class="img-fluid image" [src]="imgPath" />
    <div class="middle">
      <ng-container *ngFor="let tech of techArray">
        <div class="text">{{ tech }}</div>
      </ng-container>
    </div>
  </div>
</div>
<footer>
  <p>
    <span> {{ projectName }}</span>
  </p>
  <!-- <p class="lead" class="max-para-length">{{description}}</p> -->
  <div style="max-height: 100px; height: 100px; overflow: overlay">
    <span class="text">{{ description }}</span>
  </div>
  <!-- <a *ngIf="gitHubLink" [href]="gitHubLink" target="_blank"><i class="fa fa-github fa-lg" aria-hidden="true"></i></a>
  <a *ngIf="youtubeLink" [href]="youtubeLink" target=" _blank"><i class="fa fa-youtube fa-lg" aria-hidden="true"></i></a> -->
  <ul id="socials">
    <li *ngIf="gitHubLink">
      <a [href]="gitHubLink" target="_blank"><i class="fa fa-github fa-lg" aria-hidden="true"></i></a>
    </li>
    <li *ngIf="youtubeLink">
      <a [href]="youtubeLink" target=" _blank"><i class="fa fa-youtube fa-lg" aria-hidden="true"></i></a>
    </li>
  </ul>
</footer>
